interface ILanguage {
  name: string;
  code: string;
  rtl: boolean;
}

export const LANGUAGES: ILanguage[] = [
  { name: "한국어", code: "kor", rtl: false },
  { name: "English", code: "eng", rtl: false },
  { name: "اللُّغَة الْعَرَبِيَّة", code: "arb", rtl: true },
  { name: "Español", code: "spa", rtl: false },
  { name: "فارسی", code: "fas", rtl: true },
  { name: "Bahasa Indonesia", code: "ind", rtl: false },
];
