import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React, { useState } from "react";

export default function BibleVerse(props: {
  num: string;
  text: string;
  rtl?: boolean;
}) {
  const [marked, setMarked] = useState(false);
  return (
    <BibleVerseDiv
      onClick={() => setMarked((m) => !m)}
      marked={marked}
      rtl={props.rtl}
    >
      <VerseNum>{props.num}</VerseNum>
      <VerseText>{props.text}</VerseText>
    </BibleVerseDiv>
  );
}

const BibleVerseDiv = styled.div<{ marked: boolean; rtl?: boolean }>(
  ({ marked, rtl }) => css`
    display: flex;
    gap: 5px;
    margin: 5px 10px 5px 10px;

    ${rtl &&
    css`
      flex-direction: row-reverse;
      text-align: right;
    `}

    ${marked &&
    css`
      text-decoration: underline;
      font-weight: 700;
    `}

    &:last-of-type {
      padding-bottom: 36px;
    }
  `
);

const VerseNum = styled.span`
  font-weight: 700;
`;

const VerseText = styled.span``;
