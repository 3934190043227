import styled from "@emotion/styled";
import { useRef, useState } from "react";
import { CircularProgress, Tab, Tabs } from "@mui/material";
import React from "react";
import { useQuery } from "react-query";
import SwipeableViews from "react-swipeable-views";
import BibleVerse from "./components/BibleVerse";
import { Reset } from "styled-reset";
import LanguageControl from "./components/LanguageControl";
import loadGAP from "./loader/loadGAP";
import { useParams } from "react-router-dom";
import { LANGUAGES } from "./config";

function GapBible() {
  const [selectedBibleIndex, setSelectedBibleIndex] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);

  const params = useParams<{ lang: string }>();
  const lang = params.lang ?? "kor";
  const { data } = useQuery(["loadGAP", lang], loadGAP(lang));

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedBibleIndex(newValue);
    contentRef.current?.scrollTo({ top: 0, behavior: "auto" });
  };

  const handleChangeIndex = (index: number) => {
    setSelectedBibleIndex(index);
    contentRef.current?.scrollTo({ top: 0, behavior: "auto" });
  };

  if (!data) {
    return (
      <Loading>
        <CircularProgress />
      </Loading>
    );
  }

  const verses = data as { [key: string]: { [key: string]: string } };
  // console.log(verses);

  return (
    <Container>
      <Reset />
      <GNB>
        <LanguageControl />
        <StyledTabs
          value={selectedBibleIndex}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          {Object.keys(verses).map((title, i) => (
            <Tab label={title} key={i} />
          ))}
        </StyledTabs>
      </GNB>
      <BibleContent ref={contentRef} style={{ minHeight: "95%" }}>
        <SwipeableViews
          containerStyle={{ height: "100%" }}
          style={{ height: "100%" }}
          onChangeIndex={handleChangeIndex}
          index={selectedBibleIndex}
        >
          {Object.keys(verses).map((title, titleIndex) => (
            <div role="tabpanel" key={titleIndex} style={{ minHeight: "95%" }}>
              {Object.keys(verses[title]).map((num, i) => (
                <BibleVerse
                  key={num}
                  num={num}
                  text={verses[title][num]}
                  rtl={LANGUAGES.find((l) => l.code === lang)?.rtl}
                />
              ))}
            </div>
          ))}
        </SwipeableViews>
      </BibleContent>
    </Container>
  );
}

const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  font-size: 0.8rem;
  line-height: 1.5;
`;

const GNB = styled.div`
  display: flex;
`;

const BibleContent = styled.div`
  overflow-y: scroll;
  flex: 1;
`;

const StyledTabs = styled(Tabs)`
  &.MuiTabs-root {
    min-height: 40px;
  }

  & .MuiTab-root {
    font-size: 0.8rem;
    padding: 12px 8px;
    min-width: auto;
    min-height: auto;

    &.Mui-selected {
      color: #8b0000;
    }
  }

  & .MuiTabs-indicator {
    background-color: #8b0000;
  }
`;

export default GapBible;
