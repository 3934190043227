import { loadGAPArb } from "./loadGAPArb";
import { loadGAPEng } from "./loadGAPEng";
import { loadGAPKor } from "./loadGAPKor";
import { loadGAPSpa } from "./loadGAPSpa";
import { loadGAPFas } from "./loadGAPFas";
import { loadGAPInd } from "./loadGAPInd";

export default function loadGAP(lang: string): () => Promise<{}> {
  switch (lang) {
    case "kor":
      return loadGAPKor;
    case "eng":
      return loadGAPEng;
    case "arb":
      return loadGAPArb;
    case "spa":
      return loadGAPSpa;
    case "fas":
      return loadGAPFas;
    case "ind":
      return loadGAPInd;
  }
  return () => new Promise<{}>(() => ({}));
}
