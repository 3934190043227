import initSqlJs from "sql.js";
import sqlWasm from "../../node_modules/sql.js/dist/sql-wasm.wasm";
import { isLeapYear } from "./utils";

export async function loadGAPKor() {
  // const SQL = await initSqlJs();
  // Open the database
  // const filebuffer = fs.readFileSync("gap.db");
  // const db = new SQL.Database(filebuffer);

  const now = new Date(); // 현재 날짜 및 시간
  const year = now.getFullYear(); // 현재 연도

  const januaryFirst = new Date(year, 0, 1); // 해당 연도의 1월 1일
  const timeDifference = now - januaryFirst; // 밀리초 단위의 시간 차이

  let dayDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24)); // 일 단위로 계산
  if (isLeapYear(year) && now.getMonth() + 1 >= 3) {
    // 윤년일때는 2월 29일과 3월 1일을 같게 만듭니다.
    dayDifference -= 1;
  }

  const dataPromise = fetch("/db/gap_kor.db").then((res) => res.arrayBuffer());
  const sqlPromise = initSqlJs({ locateFile: () => sqlWasm });
  const [SQL, buf] = await Promise.all([sqlPromise, dataPromise]);
  const db = new SQL.Database(new Uint8Array(buf));

  // Execute the SQL query to retrieve the metadata for the given day
  const query = `SELECT * FROM gap WHERE day=${dayDifference}`;
  const query_results = db.exec(query)[0].values;

  const results = {
    count: query_results[0][1],
    c1: query_results[0][2],
    c2: query_results[0][3],
    c3: query_results[0][4],
    c4: query_results[0][5],
    c5: query_results[0][6],
    c6: query_results[0][7],
    c7: query_results[0][8],
  };

  // Access the metadata values

  let todaygap = [];
  let index = ["c1", "c2", "c3", "c4", "c5", "c6", "c7"];

  let book = [];
  let chapter = [];
  let init = [];
  let end = [];
  let verses = {};

  let bible_book = [
    "",
    "창세기",
    "출애굽기",
    "레위기",
    "민수기",
    "신명기",
    "여호수아",
    "사사기",
    "룻기",
    "사무엘상",
    "사무엘하",
    "열왕기상",
    "열왕기하",
    "역대상",
    "역대하",
    "에스라",
    "느헤미야",
    "에스더",
    "욥기",
    "시편",
    "잠언",
    "전도서",
    "아가",
    "이사야",
    "예레미야",
    "예레미야애가",
    "에스겔",
    "다니엘",
    "호세아",
    "요엘",
    "아모스",
    "오바댜",
    "요나",
    "미가",
    "나훔",
    "하박국",
    "스바냐",
    "학개",
    "스가랴",
    "말라기",
    "마태복음",
    "마가복음",
    "누가복음",
    "요한복음",
    "사도행전",
    "로마서",
    "고린도전서",
    "고린도후서",
    "갈라디아서",
    "에베소서",
    "빌립보서",
    "골로새서",
    "데살로니가전서",
    "데살로니가후서",
    "디모데전서",
    "디모데후서",
    "디도서",
    "빌레몬서",
    "히브리서",
    "야고보서",
    "베드로전서",
    "베드로후서",
    "요한1서",
    "요한2서",
    "요한3서",
    "유다서",
    "요한계시록",
  ];

  for (let i = 0; i < results.count; i++) {
    todaygap[i] = results[index[i]];
    end[i] = 0;
  }

  // [책];[장]:[절시작]-[절끝]   ===> 절 범위가 있는 경우
  // [책];[장]                ===> 한 장 전체가 범위인 경우
  for (let j = 0; j < results.count; j++) {
    book[j] = todaygap[j].split(";")[0];
    chapter[j] = todaygap[j].split(';')[1].split(':')[0];
    // chapter[j] = todaygap[j].split(":")[0];

    // 장의 부분만 범위에 해당하는 경우
    if (todaygap[j].includes(":")) {
      init[j] = todaygap[j].split(":")[1].split("-")[0];
      end[j] = todaygap[j].split(":")[1].split("-")[1];
    }
    // 한 장 전체가 범위인 경우
    else {
      init[j] = 1;
    }

    let index_str = bible_book[book[j]] + " " + chapter[j] + "장 ";
    if (todaygap[j].includes("-")) {
      index_str += init[j] + "-" + end[j] + "절";
      //console.log(book[j], chapter[j], init[j], end[j])
      //console.log( `SELECT * FROM bible WHERE book=${book[j]} and chapter=${chapter[j]} and verse>=${init[j]} and verse<=${end[j]} order by verse asc`)
      let bible_query_result = db.exec(
        `SELECT * FROM bible WHERE book=${book[j]} and chapter=${chapter[j]} and verse>=${init[j]} and verse<=${end[j]} order by verse asc`
      );
      // console.log(bible_query_result[0]);
      verses[index_str] = {};
      let k = init[j];
      for (let row of bible_query_result[0].values) {
        verses[index_str][k] = row[3];
        k++;
      }
    } else if (todaygap[j].includes(":")) {
      index_str += init[j] + "절";
      let bible_query_result = db.exec(
        `SELECT * FROM bible WHERE book=${book[j]} and chapter=${chapter[j]} and verse=${init[j]}`
      );

      verses[index_str] = {};
      let k = init[j];
      while (bible_query_result[0].values[k - 1]) {
        verses[index_str][k] = bible_query_result[0].values[k - 1][2];
        k++;
      }
    } else {
      let bible_query_result = db.exec(
        `SELECT * FROM bible WHERE book=${book[j]} and chapter='${chapter[j]}';`
      );

      verses[index_str] = {};
      let k = init[j];
      for (let row of bible_query_result[0].values) {
        verses[index_str][k] = row[3];
        k++;
      }
    }
  }
  return verses;
}
